<template>
    <div v-if="isOpen">
      <div class="__wrapper">
        <div style="display: block; text-align: center !important;">
            <!-- slide-viewer -->
            <div class="slide-viewer">
                <!-- Slide images -->
                <div v-for="(item, index) in infos.slide_imagefiles" v-bind:key="index" >
                  <img src="@/assets/guard.png" class="guard" :style="('width:' + guard.clientWidth + 'px;Height:' + guard.clientHeight + 'px' )" 
                      @touchstart="touchStart($event)"   @touchmove="touchMove($event)"   @touchend="touchEnd()" v-bind:key="index" v-show="page == index">
                  <img v-bind:key="index" v-bind:src="this.domain + item.image_path" class="img"  v-show="page == index">
                </div>
                <!-- /Slide images -->
              
                <!--- Swipe message--> 
                <div class="scroll-hint-icon-wrap is-active" data-target="scrollable-icon" v-show="isMobile()">
                <span class="scroll-hint-icon">
                  <div class="scroll-hint-text">スワイプできます</div>
                </span>
                </div> 
                <!-- /Swipe message --> 
                
                <!-- Page Bar -->
                <div class="box">
                  <div class="item">
                  </div>
                  <div class="item">
                    <div style="min-width:100px" >
                      <button type="button" data-role="none" class="slide-prev" aria-label="Previous" role="button" @click="prevPage"  v-show="page + 1 > 1 ">＜ 前へ</button>
                    </div>
                    
                    <div v-show="isMobile()" style="display:block;line-height:0.8;margin:20px;min-width:120px;">
                      {{ page + 1 }}<span style="font-size:50%;">ページ</span>  ／ {{ allPageCount }}<span style="font-size:50%;">ページ</span>
                      <div v-if="(status.publish_range_method==2) && read!='0'">
                          <br/>{{ maxPage }}<span style="font-size:60%;">ページまで公開中</span>
                      </div>
                      <div v-if="(status.publish_range_method==1) && read!='0' && allPageCount != maxPage ">
                        <br/>{{ maxPage }}<span style="font-size:60%;">ページまで公開中</span>
                      </div>
                    </div>
                    
                    
                    <div v-show="!isMobile()" style="display:block;line-height:1.5;margin:20px;min-width:140px;font-size:140%">
                      {{ page + 1 }}<span style="font-size:70%;">ページ</span>  ／ {{ allPageCount }}<span style="font-size:70%;">ページ</span>
                      <span v-if="(status.publish_range_method==2) && read!='0'" style="margin-left:10px;">
                        ※  {{ maxPage }}<span style="font-size:70%;">ページまで公開中</span>                      </span>
                      <span v-if="(status.publish_range_method==1) && read!='0' && allPageCount != maxPage " style="margin-left:10px;">
                        ※  {{ maxPage }}<span style="font-size:70%;">ページまで公開中</span>
                      </span>
                    </div>
                    
  
                    <div style="min-width:100px;"  >
                      <button type="button" data-role="none" class="slide-next" aria-label="Next" role="button"  @click="nextPage" v-show="isNext()" >次へ ＞</button>
                    </div>
                  </div>
                  
                  <div class="item">
                    <!--
                    <vue-qrcode v-show="!isMobile()" value="https://a9a8667695664401b4bb8e400ca149b4.vfs.cloud9.ap-northeast-1.amazonaws.com/viewer/shika-chan01?user_id=12" :options="option" tag="img" style="height:70px;margin-right:10px;"></vue-qrcode>
                    -->
                  </div>
                  
                </div>
                <!-- /Page Bar -->
                <!-- Arrow for mobile -->
                <div v-show="isMobile()" class="arrow">
                  <div class="right-btn" @click="nextPage" v-show="isNext()" :style="('top:' + guard.clientHeight / 2 + 'px !important')">{{ guard.clientHeight > 0 ?  '＞' : '' }}</div>
                  <div class="left-btn"  @click="prevPage" v-show="page + 1 > 1 " :style="('top:' + guard.clientHeight / 2 + 'px !important')">{{ guard.clientHeight > 0 ?  '＜' : '' }}</div>
                </div>
                <!-- /Arrow for mobile -->
                
            </div>
            <!-- /slide-viewer -->
            
            <!-- Error Messgae -->
            <div class="menubar">
            <div class="alert"  v-if="isErr">通信エラーが発生しています。<br/>しばらくしても本メッセージが消えない場合はブラウザを再表示してください</div>
            </div>
            <!-- /Error Messgae -->
        </div>
      </div>
    </div>
    
    <div v-else>
      <!-- 非公開メッセージ -->
      <div class="alert" style="margin-top:20px;font-size:14px;">
        <p v-html="message"></p>
      </div>
      <!-- /非公開メッセージ -->
    </div>
</template>

<script>
  import axios from 'axios';
  import moment from "moment"
  //import VueQrcode from '@chenfengyuan/vue-qrcode';
  
  if (!String.prototype.format) {
    String.prototype.format = function() {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function(match, number) { 
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
        ;
      });
    };
  }
  
  //const messages = require('./message.json');
  export default {
    components: {
      //VueQrcode
    },
    data() {
      return {
        domain  : process.env.VUE_APP_DOMAIN,
        page    : 0,          // 現在の表示ページ番号
        infos   : null,       // スライド情報 
        userId  : '',         // ユーザID
        isOpen  : false,      // スライド画面を表示するか
        slideCode: 0,         // スライドコード
        logs    :[],          // ユーザーログ 
        maxPage : -1,         // 表示可能ページ数 
        message : '',         // スライド非公開時メッセージ
        status  : {},         // スライド公開定義 
        isSimulation: false,  // シミレーションか
        isErr   : false,      // 通信エラーか
        allPageCount: 0,      // 全ページ数
        real: 0,
        guard : {             // 画像保護用の画像
          clientWidth : 0,
          clientHeight:0,
        },
        swipe: {              // スワイプ用
            flag: false,
            threshold: 10,
            start: {
                x: 0
            },
            current: {
                x: 0
            },
            distance: {
                x: 0
            },
        },
      };
    },
    created(){
      this.slideCode = this.$route.params.id
      //this.userId = this.$route.query['user_id'] || ''
      //this.console(document.referrer)
      this.userId = sessionStorage.getItem('userId')
      this.real = sessionStorage.getItem('real')
    },
    async mounted(){
      // スライド情報取得
      await this.getSeminar();
      document.title ="スライドビューワー";
      
      if (this.isSimulation){ // シミレーションモード
        this.isOpen = true
        this.userId='preview'
        if(this.real == '0'){
          this.maxPage = this.infos.slide_imagefiles.length
          this.allPageCount = this.infos.slide_imagefiles.length
        } else {
          this.polling(10000,this.checkStatus);
        }
      } else {              // 通常モード
        if (  this.userId === '' || this.userId === null || this.slideCode != sessionStorage.getItem('slideCode')){
          this.message ='講演会視聴画面にあるボタンから開いてください。'
          this.isOpen = false
          return;
        }
        this.polling(10000,this.checkStatus);
      }
      this.polling(1000,this.putMessage); 

      //this.childWindow()

      let me = this
      window.addEventListener('beforeunload', (event) => {
          event.stopImmediatePropagation();
          if (!this.isMobile()){ // PCの場合は close イベント
            me.logs.push({
              "action" :'close',
              "result" : "success",
              "page" : 0,
              "operate_time" : this.getCurrentTime(),
            })
          }
          const json =  {
              slide_code: me.slideCode,
              pj_code: me.infos.pj_code,
              user_id: me.userId,
              logs: me.logs
          } 
          const data = new Blob([JSON.stringify(json)], {
            type: "application/json",
          });
          if (me.infos && me.logs.length > 0 ){
            navigator.sendBeacon(me.domain + '/user/log',data)
          }
      });
      
    },
    async updated(){

    },
    methods: {
      //-- Click Next button 
      nextPage: function(){
        if(this.page + 1 >= this.maxPage){
          return;
        }
        this.removeStyle()
        this.logs.push({
          "action" : 'next',
          "result" : "success",
          "page" : this.page + 2,
          "operate_time" : this.getCurrentTime(),
        })
        this.pushLogs()
        
        this.page ++;
        
        document.getElementsByClassName('img')[this.page]
            .classList.add('slideAnimeRightLeft');
        this.console("next")
        this.putMessage();

      },
      //-- Click Prev button 
      prevPage: function(){
        if(this.page <= 0){
          return;
        }
        this.removeStyle()
        this.logs.push({
          "action" : 'prev',
          "result" : "success",
          "page" : this.page ,
          "operate_time" : this.getCurrentTime(),
        })
        this.pushLogs()
        this.page --;
        document.getElementsByClassName('img')[this.page]
            .classList.add('slideAnimeLeftRight');
        this.console("prev")
        this.putMessage();
      },
      // スワイプ
      touchStart: function (e) {
        this.swipe.flag = true;
        this.swipe.start.x = e.touches[0].pageX;
      },
      touchMove: function (e) {
          this.swipe.current.x = e.touches[0].pageX;
      },
      touchEnd: function () {
  
          if(this.swipe.current.x - this.swipe.start.x  > this.swipe.threshold ){
            console.log('右スワイプ');
             this.prevPage()
          }else if(this.swipe.current.x - this.swipe.start.x < this.swipe.threshold * -1){
            console.log('左スワイプ')
            this.nextPage()
          }else{
          console.log('なし');
          }
          //document.getElementById('dialog').style.display = 'none';  
        
        
          this.swipe.flag = false;
      },
      // スタイル削除
      removeStyle(){
        let lists = document.getElementsByClassName('img');
        for(let i = 0; i < lists.length; i++){
          document.getElementsByClassName('img')[i]
              .classList
              .remove('slideAnimeRightLeft');
          document.getElementsByClassName('img')[i]
              .classList
              .remove('slideAnimeLeftRight');
        }
      },
      // Change Display size for iframe
      putMessage(){
          // iframe の親にサイズ送信
          //var h = document.body.clientHeight  ;
          //var h = document.documentElement.scrollHeight;
          let img = document.getElementsByClassName('img')[this.page]
          //window.parent.postMessage(h  + 100, '*');
          let ratio = Math.ceil( (parseInt(this.infos.slide_imagefiles[this.page].px_h)  / parseInt(this.infos.slide_imagefiles[this.page].px_w)) * 10 ) / 10

          window.parent.postMessage(ratio.toFixed(3), '*');
          
          // ガード用の画像のサイズ設定
          if (img){
            this.guard.clientWidth = img.clientWidth 
            this.guard.clientHeight = img.clientHeight 
          }
          return true;
      },
      childWindow(){
        let scrollHeight = Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
        let scrollWidth = Math.max(
          document.body.scrollWidth, document.documentElement.scrollWidth,
          document.body.offsetWidth, document.documentElement.offsetWidth,
          document.body.clientWidth, document.documentElement.clientWidth
        );
        
        if(window.opener!==null){
          window.resizeTo(scrollWidth + 100,scrollHeight + 100)
        }
        console.log(window.opener)
        console.log(scrollWidth)
        console.log(scrollHeight)
      },
      //-- スライド情報取得
      async getSeminar() {
          //const pp = this.$route.query['pp'] ? "?pp=" + this.$route.query['pp'] : ''
          const pp =sessionStorage.getItem('pp') ? "?pp=" + sessionStorage.getItem('pp') : ''
          let gettm = moment(new Date).format('YYYYMMDDHHmmss').slice(0, -1);
          // パスワード認証であれば ECSへ、そうじゃない場合は Cloudfront(S3)にデータを確認する
          let url = this.domain + '/slide/' + this.slideCode + '/slide_info.json?' + gettm;
          if (pp) url = this.domain + '/api/slide/' + this.slideCode + pp 
          
          const res = await axios.get(url)
          this.infos = res.data;
          this.console(this.infos)
          if(!this.infos)
            window.location.href ='/'
          this.isSimulation = ( 'm' in this.infos && this.infos.m === 'confirm' ) ? true : false
      },
      //-- ログ送信
      async pushLogs(){
          this.console(this.userId)
          if (this.infos && this.logs.length > 0 ){
            try {
                await axios.post(this.domain + '/user/log', {
                  slide_code: this.slideCode,
                  pj_code: this.infos.pj_code,
                  user_id: this.userId,
                  logs: this.logs
                })
                this.logs.splice(-this.logs.length);
                this.logs = []
                this.isErr = false;
            } catch(e){
                this.isErr = true;
                console.error(e)
            }
          }
          return true
      },
      //-- ステータ取得反映
      async checkStatus(){
          await this.getSeminar();
          try {
            let gettm = moment(new Date).format('YYYYMMDDHHmmss').slice(0, -1);
            let url = "{0}/slide/{1}/publish_range.json?t={2}".format(this.domain,this.infos.slide_dir_name,gettm)
            this.console(url)
            const res = await axios.get(url)
            this.status = res.data;
            this.isErr = false;
          } catch (e) {
              console.error(e)
              this.isErr = true;
          }
          const today = moment(new Date)
          const date_open = moment(this.infos.date_open).format("YYYY/MM/DD HH:mm:ss")
          let diff = (moment(today).diff(date_open,'minutes'))
          let maxpage = 0
          
          //--- 表示可能ページ/全ページ数取得 ---//
          // 事前指定
          if (this.status.publish_range_method === 1){
              if( this.status.publish_range_preset?.length == 0 ){
                maxpage = 0
                this.allPageCount = 0
              } else {
                const me = this
                this.status.publish_range_preset.forEach(function(item){
                    if (parseInt(item['minute']) <= diff){
                      maxpage = item['page']
                    }
                    me.allPageCount = item['page']
                })
              }
              this.console(maxpage)
              
          }
          // リアルタイム設定 
          if (this.status.publish_range_method === 2){
              maxpage = parseInt(this.status.publish_range_realtime['page']) || -1;
              if (maxpage == -1){
                  this.isOpen = false
                  maxpage = 0
              } else {
                if(this.isSimulation){
                  this.isOpen = true
                }
              }
              this.allPageCount = this.infos.slide_imagefiles.length;
          }
          // 全公開
          if (this.status.publish_range_method === 0){
              maxpage = this.infos.slide_imagefiles.length
              this.allPageCount = this.infos.slide_imagefiles.length;
          }
          
          this.maxPage = maxpage
          if (maxpage != 0 && maxpage < this.page + 1 ){
              this.page = maxpage - 1
          }

          //--- 公開判定 ---//
          let nowtime = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
          let open = moment(this.infos.date_open).format('YYYY-MM-DD HH:mm')
          let close = moment(this.infos.date_close).format('YYYY-MM-DD HH:mm')
          
          // 公開 
          if(!this.isSimulation){
            if (this.infos !== null && (this.infos.date_open <= nowtime && this.infos.date_close >= nowtime) ){
               this.isOpen = true
               this.message = '';
               // リアルタイム設定でページに0が指定されている場合
               if ((this.status.publish_range_method === 2 || this.status.publish_range_method==1)  && this.maxPage == 0){
                  this.isOpen = false
                  this.message = 'スライド公開までしばらくお待ちください';
               }
            // 非公開
            } else {
               this.isOpen = false
               if(this.infos.date_open > nowtime){
                  this.message = "スライド公開時間まで今しばらくお待ちください<br/><h4>公開期間</h4><strong>{0}～<br/>{1}</strong>"
                                  .format(moment(open).format('YYYY年MM月DD日 HH:mm'),moment(close).format('YYYY年MM月DD日 HH:mm') );
               } else{
                  this.message = 'スライド公開期間が終了しております';
               }
            }
            // 緊急停止
            if(this.status.publish_slide_ok == "0"){
                this.isOpen = false
                this.message = '緊急停止しております。再開までしばらくお待ちください。';
            }
          } 
          this.console( this.isOpen )
          this.console( this.infos.date_open )
          this.console(this.infos.date_close )
          
          return true
      },
      //-- Polling
      polling(time,callfunc){
        callfunc()
        setTimeout(async () => {
          let res = callfunc()
          this.console('polling' + callfunc.name )
          if (res) this.polling(time,callfunc)
        },time)
      },
      //-- モバイル判定
      isMobile() {
        var userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("iphone") != -1 || userAgent.indexOf("ipad") != -1 || 
            userAgent.indexOf("android") != -1 || userAgent.indexOf("mobile") != -1 ) {
          return true;
        } else {
          return false;
        }
      },
      //-- 次のページ存在確認
      isNext(){
         // 事前設定
        if (this.status.publish_range_method === 1){
            
            if( this.status.publish_range_preset?.length >0 && this.page + 1 < this.maxPage ){
              return true
            }
        }
        if (this.page + 1 < this.infos.slide_imagefiles.length && this.page + 1 < this.maxPage ){
          return true
        }
        return false
      },
      //-- console.log
      console(input){
          if (process.env.VUE_APP_DEBUG==='true' || 'debug' in this.$route.query )
            console.log(input)
      },
      getCurrentTime(){
          var tz = -9, // JST
          now = new Date(Date.now() - (tz * 60 - new Date().getTimezoneOffset()) * 60000),
          pad = function (n){return n<10 ? '0'+n : n};
          return now.getFullYear()
            + '-' + pad(now.getMonth()+1)
            + '-' + pad(now.getDate())
            + ' ' + pad(now.getHours())
            + ':' + pad(now.getMinutes())
            + ':' + pad(now.getSeconds());
      }
    }
  }
</script>

<style scoped>
  /** for swaipe **/
  .slideAnimeRightLeft {
    animation-name:slideTextX-100;
    animation-duration:0.8s;
    animation-fill-mode:forwards;
    opacity: 0;
  }
  
  
  @keyframes slideTextX-100 {
    from {
    transform: translateX(100%);/*要素を右の枠外に移動*/
      opacity: 0;
    }
  
    to {
    transform: translateX(0);/*要素を元の位置に移動*/
      opacity: 1;
    }
  }
  
  .slideAnimeLeftRight {
    animation-name:slideTextX-200;
    animation-duration:0.8s;
    animation-fill-mode:forwards;
      opacity: 0;
  }


  @keyframes slideTextX-200 {
    from {
    transform: translateX(-100%);/*要素を右の枠外に移動*/
      opacity: 0;
    }
  
    to {
    transform: translateX(0);/*要素を元の位置に移動*/
      opacity: 1;
    }
  }


  /** swipe infomation **/
  .scroll-hint-icon-wrap{
    display:none;
  }
  
  /** wappaer **/
 .__wrapper{
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    max-width: 100vw;
    max-height: 100vh;
    justify-content:center;
    display:flex;
    overflow:hidden;
  }
  
  /** Slide image **/
  .img {
    max-width:auto;
    z-index: 1;
    border:solid 2px #eee;
    /*height: 80vh !important;*/
    max-width:95vw;
    max-height:80vh;
  }
  .spImg {
    z-index: 1;
    border:solid 2px #eee;
    max-width: 100%;
    height:auto;
  }
  
  /** Guard image for slide image **/ 
  .guard {
    position:absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 20px;
  }
  
  .slide-viewer{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:999;
    padding-top:20px;
    flex-direction:column;
    max-width:fit-content;
  }

  .slide-viewer::-webkit-scrollbar {
    width: 12px;
  }
  .slide-viewer::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 6px;
  }
  .slide-viewer::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 6px;
  }
  
  .menubar{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:999;
    max-width:100%;
  }
  
  .box {
    display: flex;
    justify-content: space-between;
    background-color: #003366 !important;
    height: 80px;
    border-radius: 0 0 10px 10px; 
    z-index: 999;
    min-width:100%;
  }
  
  .item{
    display: flex;
    color: #fff;
    font-weight:bold;
    justify-content:center;
    align-items:center;
    flex-basis: auto;
    line-height:1;
    text-align:center;
  }
  
  .slide-prev, .slide-next {
    font-weight:bold;
    border-radius:15px;
    cursor: pointer;
    background-color:#003366;
    color:#fff;
    font-size:100%;
    padding: 15px;
    border: solid 2px;
    height:80%;
  }
  
  .alert {
    position: relative;
    padding: 1rem 1rem;
    margin-top: 1rem;
    border: 1px solid transparent;
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    margin:0 auto;
    width:80%;
   }
  
  #dialog {
      display:none;
  }
  
  /** arrows for smartphone  **/
  .right-btn{
    position:fixed !important;
    right:20px !important;
    top: 0 !important;
    font-size: 25px !important;
    font-weight: bold !important;
    color:#eee !important;
    z-index:200 !important;
  }
  .left-btn{
    position:fixed !important;
    left:20px !important;
    top:0 !important;
    font-size: 25px !important;
    font-weight: bold !important;
    color:#eee !important;
    z-index:200 !important;
  }
  /** for Smartphone **/
  @media screen and (max-width: 670px) {
    .img {
      /*max-width:95vw;*/
      /*min-width:95%; */
      height:auto;
    }
    .box {
      display: flex;
      justify-content: space-between;
      background-color:#3498db;
      height: 50px;
      border-radius: 0 0 10px 10px;
      z-index: 999;
      width:100%;
    }
  
    
    /* next prev button */
    .slide-prev, .slide-next {
      font-weight:bold;
      border-radius:15px;
      cursor: pointer;
      background-color:#003366;
      color:#fff;
      font-size:100%;
      padding: 10px;
      border: solid 2px;
      height:80%;
    }
    
    /** swaipe information **/
    .scroll-hint-icon-wrap {
        display:block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        pointer-events: none;
        z-index: 3;
        -moz-animation: cssAnimation 0s ease-in 3s forwards;
        /* Firefox */
        -webkit-animation: cssAnimation 0s ease-in 3s forwards;
        /* Safari and Chrome */
        -o-animation: cssAnimation 0s ease-in 3s forwards;
        /* Opera */
        animation: cssAnimation 0s ease-in 3s forwards;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
  
    }
    .scroll-hint-icon {
        position: absolute;
        top: 60px;
        left: calc(50% - 60px);
        box-sizing: border-box;
        width: 120px;
        height: 80px;
        border-radius: 5px;
        transition: opacity .3s;
        opacity: 0.8;
        background: rgba(0,0,0,.7);
        text-align: center;
        padding: 20px 10px 10px 10px;
    }
    
    .scroll-hint-text {
        font-size: 10px;
        color: #FFF;
        margin-top: 5px;
    }
    
    .scroll-hint-icon:before {
        display: inline-block;
        width: 40px;
        height: 40px;
        color: #FFF;
        vertical-align: middle;
        text-align: center;
        content: "";
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(~@/assets/sw.svg);
    }
    
    @keyframes cssAnimation {
        to {
            width:0;
            height:0;
            overflow:hidden;
        }
    }
    @-webkit-keyframes cssAnimation {
        to {
            width:0;
            height:0;
            display:none;
            opacity:0;
        }
    }  
  
  }

</style>