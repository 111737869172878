import { createRouter,createWebHistory } from 'vue-router';
import SlideViewer from "./pages/SlideViewer.vue";

const routes = [
    { path: '/viewer/:id', name: 'sideshow', component: SlideViewer },
    { path: '/redirect/:id', component: () => import("@/pages/RedirectPage.vue"), },
    { path: '/:catchAll(.*)', component: () => import("@/pages/NotFound.vue"), }
]
 
const AppRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL), // HTML5 History モード
    routes,
})
 
export default AppRouter;
