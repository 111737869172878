<template>
<div>
  <router-view></router-view>
</div>
</template>
<script>

export default {
}
</script>
 
<style>
* { 
    margin: 0px; 
    padding: 0px; 
  }
body{
  -webkit-text-size-adjust:100%;
}
body, h1{
  margin: 0;
}
</style>
